import React, { useEffect, useMemo, useState } from "react";
import "./CanIdentifier.scss";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as InputSearch } from "./svg/InputSearch.svg";
import { ReactComponent as DeleteCan } from "./svg/trash.svg";
import { ReactComponent as SaveCan } from "./svg/Save.svg";
import { ReactComponent as EditCan } from "./svg/btnEditor.svg";
import { ReactComponent as AddNewRecord } from "./svg/Add.svg";
import { ReactComponent as CanConfirmedSuccess } from "./svg/canSuccess.svg";
import { ReactComponent as CanConfirmedError } from "./svg/canError.svg";
import { ReactComponent as WarningIcon } from "./svg/warningIcon.svg";
import { ReactComponent as CloseBtn } from "./svg/closeBtn.svg";
import { ReactComponent as ApplyBtn } from "./svg/apply.svg";
import { ReactComponent as AddNewCan } from "./svg/add_new_can.svg";
import { ReactComponent as UpdateCan } from "./svg/updateCan.svg";
import DataTable, { TableColumn } from "react-data-table-component";
import {
  CanIdentifierDTO,
  FunctionDTO,
  ICanIdentifierDTO,
  Protocol,
} from "../../../../api/auth/apiClient";
import Select from "react-select";
import { Checkbox, Hint } from "@skbkontur/react-ui";
import { selectStyles } from "./styleSelector";
import { DataSendingPeriod, optionsCanType } from "../data";
import { mapClient } from "../../../../api/auth/AxiosInstanse";
import {
  NotificationState,
  NotificationType,
} from "../../../Notification/notificationTypes";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import ModalNotification from "../../../CreateTransport/Modal_notification/ModalNotification";
import { customStylesCan } from "./stylesCan";

type CanIdentifierProps = {
  canIdentifiers: Array<CanIdentifierDTO>;
  selectedRow: FunctionDTO;
  showNotification: NotificationState;
  onChangeNotificationState: (state: NotificationState) => void;
  setCanIdentifiers: (canIdentifiers: CanIdentifierDTO[]) => void;
  setShowModalCan: (isShow: boolean) => void;
  getFunctionsList: (uvi: string, id: string | undefined) => void;
};

type ArrayCanIdentifierDTO = {
  array: CanIdentifierDTO[];
};

const logCanId = (canId: string) => {
  console.log(`CAN ID added or displayed: ${canId}`);
};

function CanIdentifier(props: CanIdentifierProps) {
  const intl = useIntl();
  const [standardCount, setStandardCount] = useState(0);
  const [loadedCount, setLoadedCount] = useState(0);
  const [confirmedCount, setConfirmedCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false);
  const [protocolError, setProtocolError] = useState<string | null>(null);
  const [unconfirmedCanIds, setUnconfirmedCanIds] = useState<string[]>([]);
  const [showModalDeleteCan, setShowModalDeleteCan] = useState(false);
  const [showModalCancelAction, setShowModalCancelAction] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    watch,
    trigger,
    formState: { errors, isValid },
  } = useForm<ArrayCanIdentifierDTO>({
    mode: "all",
    defaultValues: useMemo(() => {
      return { array: props.canIdentifiers };
    }, [props]),
  });

  useEffect(() => {
    if (props.canIdentifiers) {
      const total = props.canIdentifiers.length;
      const confirmed = props.canIdentifiers.filter(
        (item) => item.configurationConfirmed
      ).length;

      setTotalCount(total);
      setConfirmedCount(confirmed);
      setUnconfirmedCanIds(
        props.canIdentifiers
          .filter((item) => !item.configurationConfirmed)
          .map((item) => item.canId)
      );
    }
  }, [props.canIdentifiers]);

  useEffect(() => {
    if (props.selectedRow.uvi) {
      setCurrentUvi(props.selectedRow.uvi);
    }
  }, [props.selectedRow.uvi]);

  const { fields, append, remove } = useFieldArray({ control, name: "array" });
  const [canIdentifierToDelete, setCanIdentifierToDelete] = useState<number>();
  const [canIdentifierCanIndexToDelete, setCanIdentifierCanIndexToDelete] =
    useState<number>();
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [isApplyButtonEnabled, setIsApplyButtonEnabled] = useState(false);
  const [isApplied, setIsApplied] = useState(true);
  const [isEditing, setIsEditing] = useState<{ [key: number]: boolean }>({});
  const [currentUvi, setCurrentUvi] = useState(props.selectedRow.uvi);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isEditingRow, setIsEditingRow] = useState(false);
  const [isButtonsDisabled, setIsButtonsDisabled] = useState(false);

  const openModal = () => setIsOpenModal(true);
  const closeModal = () => setIsOpenModal(false);

  const applyRow = () => {
    setIsEditingRow(true);
  };

  const hasErrorsInRow = (index: number) => {
    return !!errors.array?.[index];
  };

  const startEditing = (index: number) => {
    // Начинаем редактирование и блокируем кнопки
    setEditingIndex(index);
    setIsEditingRow(true);
    setIsButtonsDisabled(true); // Блокировка кнопок
    setIsEditing({ [index]: true });
  };

  const editRow = () => {
    if (editingIndex !== null && !!errors.array?.[editingIndex]) {
      // Проверяем наличие ошибок; если есть - не разблокируем кнопки
      console.warn(
        "Невозможно применить изменения: есть ошибки в текущей строке."
      );
      return;
    }
    onApply(); // Применяем изменения
    setIsButtonsDisabled(false); // Разблокировка кнопок
  };

  const onApply = () => {
    if (editingIndex !== null && !!errors.array?.[editingIndex]) {
      console.warn(
        "Невозможно применить изменения: есть ошибки в текущей строке."
      );
      return;
    }

    setIsApplied(true);
    setIsApplyButtonEnabled(false);
    setEditingIndex(null);
    setIsEditingRow(false);
    setIsEditing({});
  };

  // const isApplyButtonVisible = isApplyButtonEnabled;

  const onSubmit: SubmitHandler<ArrayCanIdentifierDTO> = async (data) => {
    const standardProtocolCount = data.array.filter(
      (item) => item.protocol === 1
    ).length;

    if (standardProtocolCount > 2) {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: intl.formatMessage({
          id: "Максимальное количество идентификаторов со стандартным форматом - 2",
        }),
      });
      return;
    }

    const updatedData = {
      array: data.array.map((item) => {
        return new CanIdentifierDTO({
          ...item,
          canId: item.canId.replace(/^0x/, ""),
          mask: item.mask.replace(/0x/, ""),
        });
      }),
    };

    console.log("Отправка данных:", data);
    console.log("Ошибки при отправке формы:", errors);

    console.log("Submitting Data:", updatedData);
    console.log("Current selectedRow (before submission):", currentUvi);

    updatedData.array.forEach((item) => {
      if (!item.uvi) {
        console.warn("Missing UVI for item:", item);
      }
    });

    if (!isApplied) {
      onApply();
    }

    console.log("Selected row uvi:", props.selectedRow.uvi);

    if (!currentUvi) {
      console.warn("Missing currentUvi, cannot proceed with submission.");
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: intl.formatMessage({ id: "missing_uvi_error_message" }),
      });
      return;
    }

    try {
      setIsButtonsDisabled(false);
      const result = await mapClient.updateCanIdentifiers(
        currentUvi,
        updatedData.array
      );

      console.log("Server response:", result);

      if (result?.succeeded) {
        console.info("Update succeeded:", result);

        props.onChangeNotificationState({
          isShow: true,
          type: NotificationType.info,
          message: intl.formatMessage({ id: "changes_can_success" }),
        });

        reset({ array: updatedData.array });

        setEditingIndex(null);
        setIsEditing({});
        setIsEditingRow(false);

        setIsSendButtonDisabled(false);
        setIsApplyButtonEnabled(false);

        await props.getFunctionsList(currentUvi, undefined);
      } else {
        throw new Error(result?.message || "Failed to update");
      }
    } catch (error: any) {
      console.error("Error during request:", error);
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: `${intl.formatMessage({
          id: "changes_can_error",
        })}: ${error?.errors?.[0] || error.message || "Unknown error"}`,
      });
    }
  };

  const addNewRecord = () => {
    if (!currentUvi) {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: intl.formatMessage({ id: "missing_uvi_error_message" }),
      });
      return;
    }

    const newRecord = new CanIdentifierDTO({
      id: undefined,
      uvi: currentUvi,
      index: undefined,
      canId: "",
      mask: "0x7FF",
      name: "",
      period: 1,
      protocol: optionsCanType[0].value,
      format: 1,
      configurationConfirmed: false,
    });

    append(newRecord);
    setEditingIndex(fields.length);
    setIsEditing({ [fields.length]: true });
    setIsApplyButtonEnabled(true);
    setIsButtonsDisabled(true); // Disable buttons here
    setIsEditingRow(true); // Disable cancel button
  };

  function removeRecord(arrayIndex: number) {
    remove(arrayIndex);
    setIsApplyButtonEnabled(false);
  }

  function onShowDeleteCan(arrayIndex: number, index: number | undefined) {
    setCanIdentifierToDelete(arrayIndex);
    setCanIdentifierCanIndexToDelete(index);
    setShowModalDeleteCan(true);
  }

  useEffect(() => {
    if (fields.length > 0) {
      trigger();
    }
  }, [fields, trigger]);

  useEffect(() => {
    setIsSendButtonDisabled(!isValid);
  }, [isValid]);

  useEffect(() => {
    if (editingIndex !== null) {
      const hasErrors = !!errors.array?.[editingIndex];
      setIsApplyButtonEnabled(!hasErrors);

      watch([
        `array.${editingIndex}.name` as const,
        `array.${editingIndex}.canId` as const,
        `array.${editingIndex}.mask` as const,
        `array.${editingIndex}.period` as const,
        `array.${editingIndex}.protocol` as const,
        `array.${editingIndex}.format` as const,
      ]);
    }
  }, [errors, editingIndex, watch]);

  const columns: TableColumn<CanIdentifierDTO>[] = [
    {
      name: intl.formatMessage({ id: "can_name" }),
      cell: (can, index) => (
        <div className="flex-wrap">
          <input
            {...register(`array.${index}.name`, {
              maxLength: {
                value: 45,
                message: intl.formatMessage(
                  { id: "max_length_error" },
                  { max: 45 }
                ),
              },
            })}
            className={`canId ${
              errors?.array?.[index]?.name ? "text-red-500" : ""
            } ${isEditing[index] ? "active-style" : "disabled-style"}`}
            id={"name"}
            type={"text"}
            placeholder={intl.formatMessage({
              id: "can_name_placeholder",
            })}
            style={{
              backgroundColor: "transparent",
              border: "none",
              boxShadow: "none",
              height: "50px",
            }}
            disabled={!isEditing[index]}
          />
          <div className="mt-2">
            {(errors?.array ?? [])[index]?.name ? (
              <p className="text-red-500">
                {errors?.array?.[index]?.name?.message}
              </p>
            ) : null}
          </div>
        </div>
      ),
      width: "170px",
    },
    {
      name: intl.formatMessage({ id: "canId" }),
      cell: (can, index) => (
        <div className="flex-wrap">
          <Controller
            name={`array.${index}.canId`}
            control={control}
            rules={{
              required: intl.formatMessage({ id: "canId_validation" }),

              pattern: {
                value: /^[0-9a-fA-F]+$/,
                message: intl.formatMessage({ id: "canId_validation" }),
              },

              validate: (value) => {
                const cleanValue = value.startsWith("0x")
                  ? value.slice(2)
                  : value;

                if (!cleanValue) {
                  const message = intl.formatMessage({
                    id: "canId_validation",
                  });
                  console.error(`Validation Error: ${message}`);
                  return message;
                }

                const hexPattern = /^[0-9a-fA-F]+$/;
                if (!hexPattern.test(cleanValue)) {
                  const message = intl.formatMessage({
                    id: "canId_validation",
                  });
                  console.error(`Validation Error: ${message}`);
                  return message;
                }

                const allIds = getValues("array").map((item) => item.canId);
                const isDuplicate = allIds.some(
                  (id, idx) => id === value && idx !== index
                );
                if (isDuplicate) {
                  setIsApplyButtonEnabled(false);
                  // setIsSendButtonDisabled(true);
                  const message = intl.formatMessage({
                    id: "error_message_id",
                  });
                  console.error(`Validation Error: ${message}`);
                  return message;
                }

                setIsApplyButtonEnabled(true);
                // setIsSendButtonDisabled(false);

                const maxValueExtendedTrue = 0x1fffffff;
                const maxValueExtendedFalse = 0x7ff;

                const format = getValues(`array.${index}.format`);
                const numericValue = parseInt(cleanValue, 16);

                if (format === 1) {
                  if (numericValue > maxValueExtendedFalse) {
                    const message = intl.formatMessage({
                      id: "canId_validation_extended_false_maxValue",
                    });
                    console.error(`Validation Error: ${message}`);
                    return message;
                  }
                  if (cleanValue.length !== 3) {
                    const message = intl.formatMessage({
                      id: "canId_validation_extended_false",
                    });
                    console.error(`Validation Error: ${message}`);
                    return message;
                  }
                } else if (format === 2) {
                  if (numericValue > maxValueExtendedTrue) {
                    const message = intl.formatMessage({
                      id: "canId_validation_extended_true_maxValue",
                    });
                    console.error(`Validation Error: ${message}`);
                    return message;
                  }
                  if (cleanValue.length !== 8) {
                    const message = intl.formatMessage({
                      id: "canId_validation_extended_true",
                    });
                    console.error(`Validation Error: ${message}`);
                    return message;
                  }
                }

                return true;
              },
            }}
            render={({ field }) => (
              <input
                {...field}
                type="text"
                id="canId"
                placeholder="CAN ID"
                className={`canId ${
                  isEditing[index] ? "text-normal" : "text-gray-400"
                }`}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  boxShadow: "none",
                }}
                disabled={
                  !isEditing[index] ||
                  (editingIndex !== index &&
                    (!!can.canId || getValues(`array.${index}.period`) === 0))
                }
                value={field.value ? `0x${field.value.replace(/^0x/, "")}` : ""}
                onChange={(e) => {
                  const inputValue = e.target.value.replace(/^0x/, "");
                  field.onChange(inputValue);

                  trigger(`array.${index}.canId`);
                }}
              />
            )}
          />
          <div className="mt-2">
            {(errors?.array ?? [])[index]?.canId ||
            !getValues(`array.${index}.canId`) ? (
              <p className="">
                {errors?.array?.[index]?.canId?.message ||
                  intl.formatMessage({ id: "input_required" })}
              </p>
            ) : null}
          </div>
        </div>
      ),
      width: "120px",
    },

    {
      name: intl.formatMessage({ id: "can_mask" }),
      cell: (can, index) => {
        const formatValue = watch(`array.${index}.format`);

        return (
          <div className="flex-wrap">
            <input
              {...register(`array.${index}.mask`, {})}
              className={`canId ${errors?.array?.[index]?.mask ? "text-red-500" : ""} 
                          ${isEditing[index] ? "active-style" : "disabled-style"}`}
              id={"mask"}
              type={"text"}
              style={{
                backgroundColor: "transparent",
                border: "none",
                boxShadow: "none",
                height: "50px",
              }}
              disabled={!isEditing[index] || formatValue === 1}
            />
            <div className="mt-2">
              {(errors?.array ?? [])[index]?.mask ? (
                <p className="text-red-500">
                  {errors?.array?.[index]?.mask?.message}
                </p>
              ) : null}
            </div>
          </div>
        );
      },
      width: "100px",
    },
    {
      name: intl.formatMessage({ id: "protocol" }),
      cell: (can, index) => (
        <div className="flex-wrap">
          <Controller
            name={`array.${index}.protocol`}
            control={control}
            rules={{
              validate: (value) => {
                if (!value) {
                  return intl.formatMessage({ id: "can_input_required" });
                }
                return true;
              },
            }}
            render={({
              field: { onChange, name, value },
              fieldState: { error },
            }) => (
              <select
                name={name}
                onChange={(e) => {
                  const selectedProtocol = Number(e.target.value);
                  onChange(selectedProtocol);

                  if (
                    selectedProtocol === 1 &&
                    getValues(`array.${index}.format`) === 1
                  ) {
                    setValue(`array.${index}.mask`, "0x7FF");
                  } else if (
                    selectedProtocol === 1 &&
                    getValues(`array.${index}.format`) === 2
                  ) {
                    setValue(`array.${index}.mask`, "0x1FFFFFFF");
                  } else if (selectedProtocol === 2) {
                    setValue(`array.${index}.mask`, "0x03FFFF00");
                    setValue(`array.${index}.format`, 2);
                  }
                }}
                value={value || ""}
                className={`canId ${error ? "text-red-500" : ""} ${
                  isEditing[index] ? "active-style" : "disabled-style"
                }`}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  boxShadow: "none",
                  padding: "5px",
                }}
                disabled={!isEditing[index]}
              >
                {optionsCanType.map((canOption) => (
                  <option key={canOption.value} value={canOption.value}>
                    {canOption.label}
                  </option>
                ))}
              </select>
            )}
          />
          <div className="mt-2">
            {(errors?.array ?? [])[index]?.protocol && (
              <p className="text-red-500">
                {(errors?.array ?? [])[index]?.protocol?.message ||
                  intl.formatMessage({ id: "can_input_required" })}
              </p>
            )}
          </div>
        </div>
      ),
      width: "135px",
    },
    {
      name: intl.formatMessage({ id: "can_period" }),
      cell: (can, index) => (
        <div className="flex-wrap">
          <input
            {...register(`array.${index}.period`, {
              required: intl.formatMessage({ id: "can_input_required" }),
              pattern: {
                value: /^\d+([.]\d)?$/,
                message: intl.formatMessage({ id: "can_period_validation" }),
              },
            })}
            className={`canId ${
              errors?.array?.[index]?.period ? "text-red-500" : ""
            } ${isEditing[index] ? "active-style" : "disabled-style"}`}
            id={"period"}
            type={"text"}
            placeholder={intl.formatMessage({
              id: "placeholder_choose_period",
            })}
            style={{
              backgroundColor: "transparent",
              border: "none",
              boxShadow: "none",
              height: "50px",
            }}
            disabled={!isEditing[index]}
          />
          <div className="mt-2">
            {(errors?.array ?? [])[index]?.period ||
            !getValues(`array.${index}.period`) ? (
              <p className="text-red-500">
                {errors?.array?.[index]?.period?.message ||
                  intl.formatMessage({ id: "input_required" })}
              </p>
            ) : null}
          </div>{" "}
        </div>
      ),
      width: "100px",
    },
    {
      name: intl.formatMessage({ id: "can_type_extended" }),

      cell: (can, index) => {
        const protocolWatch = watch(`array.${index}.protocol`);

        return (
          <div className="flex-wrap">
            <Controller
              name={`array.${index}.format`}
              control={control}
              render={({ field: { onChange, name, value } }) => (
                <select
                  name={name}
                  disabled={
                    !isEditing[index] ||
                    protocolWatch === 2 ||
                    getValues(`array.${index}.protocol`) === 2 ||
                    (editingIndex !== index &&
                      (!!can.canId || getValues(`array.${index}.period`) === 0))
                  }
                  onChange={(e) => {
                    const selectedFormat = Number(e.target.value);
                    onChange(selectedFormat);

                    trigger(`array.${index}.canId`);

                    const currentProtocol = getValues(
                      `array.${index}.protocol`
                    );
                    if (currentProtocol === 1 && selectedFormat === 1) {
                      setValue(`array.${index}.mask`, "0x7FF");
                    } else if (currentProtocol === 1 && selectedFormat === 2) {
                      setValue(`array.${index}.mask`, "0x1FFFFFFF");
                    } else if (currentProtocol === 2 && selectedFormat === 2) {
                      setValue(`array.${index}.mask`, "0x03FFFF00");
                    }
                  }}
                  value={value || ""}
                  className={`canId ${isEditing[index] ? "text-normal" : "text-gray-400"}`}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    boxShadow: "none",
                    padding: "5px",
                  }}
                >
                  <option value="1">11 - bit (Standard)</option>
                  <option value="2">29 - bit (Extended)</option>
                </select>
              )}
            />
            <div className="mt-2">
              {(errors?.array ?? [])[index]?.format && (
                <p>
                  {(errors?.array ?? [])[index]?.format?.message || "Errors!!!"}
                </p>
              )}
            </div>
          </div>
        );
      },
      width: "110px",
    },
    {
      name: intl.formatMessage({ id: "can_action" }),
      cell: (can, arrayIndex) => {
        return (
          <div className="flex justify-center items-center mb-2.5">
            <DeleteCan
              width="20"
              height="20"
              onClick={() => onShowDeleteCan(arrayIndex, can.index)}
            />
            {editingIndex !== arrayIndex ? (
              <EditCan
                className="ml-2"
                width="17"
                height="17"
                onClick={() => {
                  startEditing(arrayIndex);
                  applyRow();
                }}
              />
            ) : (
              <ApplyBtn
                type="button"
                className={`ml-2 ${isApplyButtonEnabled ? "" : "disabled"}`}
                width="20"
                height="20"
                onClick={editRow}
                disabled={!isApplyButtonEnabled}
              />
            )}
          </div>
        );
      },
      width: "auto",
    },
  ];

  return (
    <>
      {showModalDeleteCan ? (
        <div>
          <ModalNotification
            onYesModalActions={() => {
              removeRecord(canIdentifierToDelete!);
              setShowModalDeleteCan(false);
            }}
            modalQuestion={intl.formatMessage({
              id: "delete_can_identifier_question",
            })}
            onNoModalActions={() => setShowModalDeleteCan(false)}
          />
        </div>
      ) : null}

      {showModalCancelAction ? (
        <div>
          <ModalNotification
            onYesModalActions={() => setShowModalCancelAction(false)}
            modalQuestion={intl.formatMessage({
              id: "cancel_action_question",
            })}
            onNoModalActions={() => props.setShowModalCan(false)}
          />
        </div>
      ) : null}

      <div className="modal_can">
        <div className="modal_can_container">
          <form onSubmit={handleSubmit(onSubmit)} className="modal_can_form">
            <div className="modal_can_header">
              <div className="modal_can_form_title">
                <h3>
                  <FormattedMessage id="changes_CanId" />
                </h3>
                <img
                  src="/image/btn-remove/btn-remove.svg"
                  alt="Close form"
                  onClick={() => props.setShowModalCan(false)}
                />
              </div>
              {/*<div className="modal_can_form_search mt-3">
                <div className="can_search flex">
                  <div className="searchImageCan">
                    <InputSearch />
                  </div>
                  <input
                    type="text"
                    className="searchInputCan"
                    placeholder={intl.formatMessage({ id: "search_CanId" })}
                  />
                </div>
              </div>*/}
            </div>
            <div className="modal_can_form_body mt-3.5">
              <div className="flex justify-start">
                <button
                  type="button"
                  className={`add_new_record ${isButtonsDisabled ? "disabled" : ""}`}
                  disabled={isButtonsDisabled}
                  onClick={addNewRecord}
                >
                  <AddNewCan className="ml-2.5" />
                  <span className="ml-2.5">
                    {intl.formatMessage({ id: "add_can" })}
                  </span>
                </button>
                {/* <div className="ml-5">
                  <button
                    type="button"
                    className={`update_can ${isEditingRow ? "disabled" : ""}`}
                    disabled={isEditingRow}
                    onClick={() =>
                      props.getFunctionsList(props.selectedRow.uvi, undefined)
                    }
                  >
                    <UpdateCan className="ml-7" />
                    <span className="ml-2">
                      {intl.formatMessage({ id: "update" })}
                    </span>
                  </button>
                </div> */}
              </div>
              <div className="mt-3.5">
                <DataTable
                  columns={columns}
                  data={fields}
                  noDataComponent={intl.formatMessage({ id: "empty_table" })}
                  fixedHeader
                  striped={false}
                  fixedHeaderScrollHeight="450px"
                  pointerOnHover={true}
                  persistTableHead
                  customStyles={customStylesCan}
                />
              </div>
            </div>

            {/* <div className="error-message text-red-500 mt-2 text-[12px] opacity-70">
              * {intl.formatMessage({ id: "error_message_protocol" })}
            </div> */}

            <div className="flex gap-1 items-center mt-2 text-sm text-color16">
              <FormattedMessage id="can_downloaded" /> {confirmedCount}{" "}
              <FormattedMessage id="can_from" /> {totalCount}{" "}
              <CanConfirmedSuccess className="w-6 h-6" />{" "}
              {confirmedCount !== totalCount && (
                <>
                  {" "}
                  <FormattedMessage id="can_unconfirmed" />{" "}
                  {totalCount - confirmedCount}{" "}
                  <button
                    onClick={openModal}
                    type="button"
                    className="underline underline-offset-2 text-color14"
                  >
                    <FormattedMessage id="can_read_more" />
                  </button>
                  <CanConfirmedError className="w-6 h-6" />
                </>
              )}
              {/* Модальное окно */}
              {isOpenModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
                  <div className="relative bg-white rounded-lg p-6 max-w-sm mx-auto text-center">
                    <button
                      className="absolute top-4 right-4"
                      onClick={closeModal}
                    >
                      <CloseBtn />
                    </button>
                    <div className="flex justify-center mb-2">
                      <WarningIcon className="w-10 h-10" />
                    </div>
                    <div className="text-xl font-bold text-color15 mb-3">
                      <FormattedMessage id="can_attention" />
                    </div>
                    <div className="text-sm text-color8">
                      <div className="">
                        CAN ID{" "}
                        {unconfirmedCanIds.length > 0 && (
                          <span className="underline underline-offset-2 text-color10">
                            {unconfirmedCanIds.join(",")}
                          </span>
                        )}{" "}
                        <FormattedMessage id="can_attention_extends" />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Модальное окно */}
            </div>

            <div className="modal_can_form_footer mt-5">
              <div className="flex items-center">
                {/* <div>
                  <button
                    type="button"
                    className={`btn ${isApplyButtonEnabled ? "" : "disabled"}`}
                    disabled={!isApplyButtonEnabled}
                    onClick={onApply}
                  >
                    <span className="ml-2">
                      <FormattedMessage id="save" />
                    </span>
                  </button>
                </div> */}

                <button
                  type="submit"
                  className={`btn ${isSendButtonDisabled ? "disabled" : ""}`}
                  disabled={isSendButtonDisabled}
                >
                  <span className="ml-2">
                    <FormattedMessage id="save" />
                  </span>
                </button>

                <div className="ml-7 mr-7">
                  <button
                    className={`cancel ${isButtonsDisabled ? "disabled" : ""}`}
                    disabled={isButtonsDisabled}
                    type="button"
                    onClick={() => setShowModalCancelAction(true)}
                  >
                    <span>
                      <FormattedMessage id="cancel" />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default CanIdentifier;
